<template>
  <div :class="classBlock">
    <div :class="classBlock+'_textContainer'">
      <v-row>
        <v-col md="6">
          <section>
            <h2>A PROPOS</h2>
            <p>
              Formé par les compagnons du devoir de Seynod, j’ai eu l’obtention de mon CAP Menuisier Agenceur et de mon BP menuisier agenceur. 
              Mon expérience s’étale sur plus de 5 ans en tant que poseur-installateur dont 3 ans au sein de Mobalpa. 
              Je suis capable, grâce à mon expérience et à un outillage portatif professionnel adapté à toute situation, de poser un large choix de gammes et marques différentes 
              (Ikea, Socooc, Cuisinella, Lapeyre, Aviva, Caseo, Mobalpa, Schmidt, Perene…)<br>
              <br>
              Sérieux, organisé, réactif je répondrai à vos demandes dans les meilleurs délais.
            </p>
            <h3>Mr Rogazy</h3>
          </section>
        </v-col>
        <v-col :class="classBlock+'_pict'" md="6">
          <v-img :class="[classBlock+'-img']" height="100%" contain :src="getImageKriss"></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'About',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'About'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },
      getImageKriss: function () {
        return require("@/assets/img/Kriss.jpg")
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'About';
    .About {
        // --------------------------------------------------------------------- CONFIG
        background-color: $green2;
        color: $white;
        // --------------------------------------------------------------------- BLOCK
        &_textContainer{
          margin-left: 10vw;
          p{
            padding-right: 5vw;
          }
          @media screen and (max-width: 640px) {
            padding: 60px 0;
          }
        }

        &_pict{
          padding: 0 !important;
          /* background-image: url('../assets/img/Kriss.jpg');
          background-size: cover;
          background-position-x: center; */
          @media screen and (max-width: 640px) {
            display: none;
          }
        }
        // --------------------------------------------------------------------- ELEMENTS
        h2{
          font-family: 'PlayfairDisplay', Arial, Helvetica, sans-serif;
          font-weight: 600;
          font-size: 30px;
        }
        h3{
          color: $white;
          text-align: right;
          font-size: 40px;
          padding-right: 30px;
          padding-top: 30px;
        }
        section{
          /* padding: 30px 0; */
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

