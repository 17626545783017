<template>
  <div :class="classBlock">
    <div :class="classBlock+'_textWarpper'">
      <div :class="classBlock+'_textContainer'">
        <h2>IPA POSE</h2>
        <p>
          Sur la région des deux Savoies, de Saint-Julien-en-Genevois à Chambéry en passant par Annecy et Aix-les-Bains,<br>
          Je réalise le montage, l'installation et la pose de votre mobilier.<br>
          De la simple cuisine à la cuisine haute gamme, dressing, placard avec porte coulissante, agencement sur mesure, parquet, salle de bains et paroi de douche.
        </p>
        <p>
          Je propose aussi mes services aux sociétés qui souhaitent aménager leurs espaces (bureau et mobilier de rangement, co-working), 
          ainsi que leurs espaces de vie (cuisine, aménagement sur mesure….) <i>hors livraison</i>.
        </p>
        <p>
          Mon cœur d'activité est la pose de cuisine pour des enseignes telles que Mobalpa.<br>
          Cuisiniste, magasin d’agencement ou décorateur d’intérieur mon profil pourrait vous intéresser.
        </p>
      </div>
    </div>
    
    <div :class="[classBlock+'_sliderWarpper']">
      <!-- <div :class="[classBlock+'_slider']">
        <div :class="[classBlock+'_slide']" v-for="i in photosNb" :key="i">
          <v-img height="250" width="500" :src="getImageUrl(i)"></v-img>
        </div>
      </div> -->
      <Slider :class="[classBlock+'_slider']"/>
    </div>
    <!-- <div :class="[classBlock+'_photo', classBlock+'_photo-'+i]" v-for="i in photosNb" :key="i"></div> -->
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies

  // -------------------------------------------- imports components
  import Slider from './Slider.vue';

  export default {
    // -------------------------------------------- init
    name: 'Presentation',
    // components imported
    components: {
      Slider
    },
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Presentation',
      photosNb: 7,
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {
      
    },

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'Presentation';
    .Presentation {
        // --------------------------------------------------------------------- CONFIG
        // height: 100vh;
        width: 100vw;
        position: relative;
        // --------------------------------------------------------------------- BLOCK
        &_textWarpper{
          background-image: url('../assets/img/outils.jpg');
          background-size: cover;
          background-position-x: center;
        }
        &_textContainer{
          width: 50vw;
          margin: 0 10vw;
          text-align: left;
          padding: 25vh 0 15vh;
          
          p{
            text-justify: distribute;
          }
          @media screen and (max-width: 640px) {
            width: 80vw;
          }
          
        }
        &_photo{
          background-size: contain;
          background-position: center left;
          display: block;
          &-1{
            background-image: url('../assets/img/01.jpg');
            width: 30%;
            height: 30%;
          }
        }
        &_slide{
          // background-color: black;
          background-size: cover;
          width: 250px;
          height: 250px;
        }
        &_sliderWarpper{
          position: relative;
          margin-top: -7.5vh;
          margin-bottom: -7.5vh;
          z-index: 1;
          overflow-x: hidden;
        }
        h2{
          font-family: 'PlayfairDisplay', Arial, Helvetica, sans-serif;
          color: $green;
          font-size: 30px;
        }
        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

