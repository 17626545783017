<template>
  <div :class="classBlock">
    <Menu :class="[classBlock+'-menu ' + getLogoColor]" :colorBurger="getLogoColor"/>
    <router-link :to="'/'">
      <div ref="logo" :class="[classBlock+'-logo ' + getLogoColor, classBlock+'-logo_' + logoPosition]"></div>
    </router-link>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import Menu from './Menu.vue'
  import gsap from 'gsap'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'HeaderMenu',
    // components imported
    components: {Menu},
    // component properties
    props: {
      classNames: [], // string[],
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'HeaderMenu',
      logoColor: 'white',
      logoPosition: 'middle'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },

      getLogoColor: function () {
        return this.logoColor
      }
    },

    // -------------------------------------------- methods
    methods: {
      showLogo() {
        gsap.to(this.$refs.logo, {opacity:1})
      },
      logoToTop() {
        this.logoPosition = 'top'
      },
      scrollHandler: function(evt) {
        console.log('scrollHanlder', evt)
        let screenHeight = window.innerHeight
        let scrollTop = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)
        if (scrollTop > (screenHeight - 100) && scrollTop < ((screenHeight*2))) {
          this.logoColor = 'black'
        } else {
          this.logoColor = 'white'
        }
        console.log(document.documentElement.scrollTop, window.pageYOffset)
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      // if (!this.logoColor) this.logoColor = 'black'
      this.delayedCall = gsap.delayedCall(0.3, this.showLogo);
      this.delayedCall = gsap.delayedCall(1.3, this.logoToTop);
      this.scrollHandler()
      window.addEventListener('scroll', this.scrollHandler)
    },

    // -------------------------------------------- destroy
    destroy: function () {
      window.removeEventListener('scroll', this.scrollHandler)
    }
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'HeaderMenu';
    .HeaderMenu {
        // --------------------------------------------------------------------- CONFIG
        margin: 30px 0;
        position: fixed;
        width: 100vw;
        top: 0;
        padding: 0 40px;
        z-index: 3;
        @include mobile {
          width: 100%;
        }
        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS
        &-logo{
          $size: 20vw;
          $sizeSmall: 8vw;
          $halfSize: $size / 2;
          $left: 50vw - $halfSize;
          width: $size;
          height: $size;
          background-size: contain;
          display: inline-block;
          z-index: 3;
          position: absolute;
          left: $left;
          opacity:0;
          transition: 2s;
          &.white{
            background-image: url("../assets/svg/IPA-white.svg");
          }
          
          &.black{
            background-image: url("../assets/svg/IPA-color.svg");
          }
          &_top{
            $left: 50vw - ($sizeSmall/2);
            width: $sizeSmall;
            height: $sizeSmall;
            left: $left;
            top: 0;
            @media screen and (max-width: 640px) {
              width: 30vw;
              height: 30vw;
              $left: 50vw - (30vw/2);
              left: $left;
            }
          }
          &_middle{
            $top: calc(50vh - #{$halfSize});
            top: $top;
          }
        }
        &-menu{
          display: inline-block;
        }
        &-subline{

        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

