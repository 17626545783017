<template>
  <div :class="classBlock">
    <a href="tel:+33767045738">
      <v-icon color="#FFF">
        mdi-phone
      </v-icon>
       07 67 04 57 38
    </a>
    <span :class="classBlock+'_spacer'"></span>
    <a href="mailto:contact@ipapose.com">
      <v-icon color="#FFF">
        mdi-gmail
      </v-icon>
       contact@ipapose.com
    </a>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Contact',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Contact'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'Contact';
    .Contact {
        // --------------------------------------------------------------------- CONFIG
        text-align: center;
        color: #FFF;
        background-color: $green;
        padding: 20px 0;
        position: fixed;
        bottom: 0;
        width: 100vw;
        z-index: 5;
        @media screen and (max-width: 640px) {
          /* top: 0;
          bottom:0;
          position:fixed;
          overflow-y:scroll;
          overflow-x:hidden; */
        }
        // --------------------------------------------------------------------- BLOCK
        &_spacer{
          width: 20px;
          display: inline-block;
          @media screen and (max-width: 640px) {
            display: block;
          }
        }
        // --------------------------------------------------------------------- ELEMENTS
        a{
          font-weight: 100;
          color: #FFF;
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

