import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404.vue'
// import Project from '../views/Project.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }/* ,
  {
    path: '/projects/:url',
    name: 'Project',
    component: Project,
    props: true
  } */
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
