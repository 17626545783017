<template>
  <div ref="container" :class="classBlock">
    <!-- <div :class="classBlock+'-img'" v-for="(image, id) in nbPict" :style="getImageUrlStyle(image)" :key="id"></div> -->
    <v-img :class="classBlock+'-img'" v-for="(image, id) in nbPict" :src="getImageUrl(image)" :key="id"></v-img>
    <v-img :class="[classBlock+'-img', classBlock+'-imgLast']" :src="getImageUrl('01')"></v-img>
    <v-img :class="[classBlock+'-img', classBlock+'-imgFirst']" :src="getImageUrl('02')"></v-img>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import gsap from 'gsap'
  import { Linear } from 'gsap/all';
  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Slider',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Slider',
      nbPict: 16,
      imgWidth: 0
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {
      leadingZero (value) {
        return ('0' + value).slice(-2)
      },
      getImageUrl (value) {
        console.log(this.leadingZero(value))
        return require("@/assets/img/"+this.leadingZero(value)+".jpg")
      },
      getImageUrlStyle (value) {
        console.log(this.leadingZero(value))
        return "background-image: url('../assets/img/"+this.leadingZero(value)+".jpg');"
        // return ""
      },
      click(index) {
        console.log(index)
      },
      resizeHandler () {
        let width = window.innerWidth
        this.imgWidth = width/2

        gsap.to(this.$refs.container, 0, {x:0})
        gsap.fromTo(this.$refs.container, 150, {x:0}, {x:-((this.imgWidth*this.nbPict) + (this.nbPict*2)), ease:Linear.easeNone, repeat:-1})
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler)
    },

    // -------------------------------------------- destroy
    destroy: function () {
      window.removeEventListener('resize', this.resizeHandler)
    }
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'Slider';
    .Slider {
        // --------------------------------------------------------------------- CONFIG
        width: calc(18 * (50vw + 2px));
        transition: 0s;
        height: 50vh;

        &:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          height: 100%;
        }

        // --------------------------------------------------------------------- BLOCK
        &-img{
          height: 35vh;
          width: 50vw;
          display: inline-block !important;
          margin-left: 2px;
          vertical-align: middle;
          transition: 3s;
          &:hover{
            height: 50vh;
            top: 0;
          }
        }
        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

