<template>
  <div :class="classBlock">
    <div :class="classBlock + '-background background'"></div>
    <!-- <header-cc logoColor="black"></header-cc> -->
    <HeaderMenu />
    <HomeBanner />
    <Presentation />
    <About />
    <v-carousel hide-delimiter-background show-arrows-on-hover cycle height="100vh">
      <v-carousel-item
        v-for="(image, id) in nbPict"
        :key="id"
        :src="getImageUrl(image)"
      ></v-carousel-item>
    </v-carousel>
    <Prestation />
    <Contact />
    <!-- <v-main class="page-content" fill-height>
      
      <v-row align="center">
        <v-col>
          <div :class="classBlock + '-text'">
            <h1>
              Pose<br>
              de notre<br> 
              site en cours.
            </h1>
            <h3>Mr Rogazy</h3>
            <p>
              Pose de cuisine.<br>
              <a href="tel:0767045738">tel : 07 67 04 57 38</a><br>
              <a href="mailto:contact@ipapose.com">mail : contact@ipapose.com</a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-main> -->
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import { mapGetters } from 'vuex'
  import HomeBanner from '../components/HomeBanner.vue'
  import HeaderMenu from '../components/HeaderMenu.vue'
  import Presentation from '../components/Presentation.vue'
  import About from '../components/About.vue'
  import Prestation from '../components/Prestation.vue'
  import Contact from '../components/Contact.vue'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Home',
    // components imported
    components: {HomeBanner, HeaderMenu, Presentation, About, Prestation, Contact},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // metas
    metaInfo: {
      title: 'IPA pose',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: "Sur la région des deux Savoies, de Saint-Julien-en-Genevois à Chambéry en passant par Annecy et Aix-les-Bains, Montage, installation et pose de votre mobilier. De la simple cuisine à la cuisine haute gamme, dressing, placard avec porte coulissante, agencement sur mesure, parquet, salle de bains et paroi de douche.",
      }],
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Home',
      nbPict: 16
    }),

    // -------------------------------------------- computed properties
    computed: {
      ...mapGetters({
        getAllProjects: 'projects/getAllProjects',
      }),

      // format data to an array
      projects: function () {
        return Object.entries(this.getAllProjects)
      },

      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {
      leadingZero (value) {
        return ('0' + value).slice(-2)
      },

      getImageUrl (value) {
        console.log(this.leadingZero(value))
        return require("@/assets/img/"+this.leadingZero(value)+".jpg")
      },
    },

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';
    // Component name
    $COMPONENT: 'Home';
    .Home {
        // --------------------------------------------------------------------- CONFIG
        z-index: 1;
        // --------------------------------------------------------------------- BLOCK
        .Menu{
          position: absolute;
        }
        // --------------------------------------------------------------------- ELEMENTS
        .page-content{
          height: 100vh;
        }
        .row{
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        &-text{
          text-align: center;
          width: 100%;
          position: relative;
          bottom: inherit;
        }
        &-pict{
          background-image: url('../assets/svg/IPA-color.svg');
          background-size: contain;
          background-position: center left;
          display: block;
          background-position: center center;
          height: 32vh;
          width: 90vw;
          top: 60px;
          left: 5vw;
          z-index: -1;
          overflow: hidden;
          margin: 20px 0 0;
        }
        &-background{
          background-color: $home;
          width: 100vw;
          min-height: 100vh;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
        }

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS
        p{
          margin: 30px 0;
        }

        h1 {
          font-size: 31px;
          margin: 20px 0;
        }
        h3 {
          @include mobile {
            font-size: 36px;
          }
        }
        .button{
          margin: auto;
        }

        .v-window__next{
          right: 0;
        }

        // --------------------------------------------------------------------- PATCHES

    }

</style>

