<template>
  <div :class="classBlock">
    <v-row>
      <v-col>
        <div :class="classBlock+'_textContainer'">
          <h2>Les Prestations</h2>
          <ul>
            <li>
              Démontage, reprise et gestion du recyclage de votre ancien mobilier<br>
              (dans la limite du chargement que mon véhicule pourra supporter).
            </li>
            <li>Métrage (cuisiniste)</li>
            <li>Montage et pose de votre nouvel aménagement.</li>
            <li>Nettoyage du chantier et reprise des déchets.</li>
            <li>Service après vente</li>
            <li>Garantie décennale</li>
          </ul>
          <br>
          <strong>Avec Ipa pose prenez vos aménagements au sérieux</strong>

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Prestation',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Prestation'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'Prestation';
    .Prestation {
        // --------------------------------------------------------------------- CONFIG
        padding-bottom: 10vh;
        // --------------------------------------------------------------------- BLOCK
        &_textContainer{
          width: 50vw;
          margin: 10vh auto;
          @media screen and (max-width: 640px) {
            width: 80vw;
          }
        }

        // --------------------------------------------------------------------- ELEMENTS
         h2{
          font-family: 'PlayfairDisplay', Arial, Helvetica, sans-serif;
          font-weight: 600;
          font-size: 30px;
          margin-bottom: 30px;
          text-transform: uppercase;
        }
        li{
          margin-left: 20px;
          margin-bottom: 10px;
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

