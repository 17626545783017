<template>
  <div class="App">
    <!-- router-view with transition -->
    
    <transition 
        name="fade"
        mode="out-in"
      >
        <router-view ref="routerViewRef"/>
    </transition>
    <!-- <v-main>
      <transition 
        :css="false"
        mode="out-in"
        @enter="enter"
        @leave="leave"
      >
        <router-view ref="routerViewRef"/>
      </transition>

      <v-row align="center">
        <v-col class="text-center" cols="12" sm="4">
          <div class="my-2">
            <v-btn small color="primary" @click="setTransitionLeft()">transition leave LEFT</v-btn>
          </div>
          <div class="my-2">
            <v-btn small color="primary" @click="setTransitionRight()">transition leave RIGHT</v-btn>
          </div>
          <div class="my-2">
            current transition : {{transitionType}}
          </div>
        </v-col>
      </v-row>
    </v-main> -->
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import {enterTransition, leaveTransition, ETransitionType} from './transitions/transitions'
  import { mapActions, mapGetters } from 'vuex'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'App',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'App',
      transitionType: ETransitionType.LEFT
    }),

    // -------------------------------------------- computed properties
    computed: {
      ...mapGetters({
        getStoresIsLoaded: 'getStoresIsLoaded'
      }),
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {
      ...mapActions({
        initStores: 'initStores'
      }),
      enter: function (el, done) {
        enterTransition(this.transitionType, done)
      },
      /* appear transition for first loading
      appear: function (el, done) {
        enterTransition(this.transitionType, done)
      }, */
      leave: function (el, done) {
        leaveTransition(this.transitionType, done);
      },
      setTransitionLeft: function () {
        this.transitionType = ETransitionType.LEFT
      },
      setTransitionRight: function () {
        this.transitionType = ETransitionType.RIGHT
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      this.initStores();
    },

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import './styles/reference';
    // --------------------------------------------------------------------- CONFIG
    @font-face {
      font-family: 'GTAmerica'; 
      src: url("assets/fonts/GT-America-Bold-Italic.ttf") format("truetype");
      font-weight: 600;
      font-style: italic;
    }
    @font-face {
      font-family: 'GTAmerica'; 
      src: url("assets/fonts/GT-America-Regular.ttf") format("truetype");
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'GTAmerica'; 
      src: url("assets/fonts/GT-America-Bold.ttf") format("truetype");
      font-weight: 600;
      font-style: bold;
    }
    /* @font-face {
      font-family: 'MonumentExtended'; 
      src: url("assets/fonts/MonumentExtended-Regular.ttf") format("truetype");
      font-weight:normal;
      font-style:normal;
    }
    @font-face {
      font-family: 'MonumentExtended'; 
      src: url("assets/fonts/MonumentExtended-Ultrabold.ttf") format("truetype");
      font-weight:900;
      font-style:normal;
    } */
    @font-face {
      font-family: 'PlayfairDisplay'; 
      src: url("assets/fonts/PlayfairDisplay-Black.ttf") format("truetype");
      font-weight: 900;
      font-style: nomral;
    }
    @font-face {
      font-family: 'PlayfairDisplay'; 
      src: url("assets/fonts/PlayfairDisplay-Regular.ttf") format("truetype");
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'PlayfairDisplay'; 
      src: url("assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
      font-weight: 600;
      font-style: bold;
    }
    @font-face {
      font-family: 'SmoothStone'; 
      src: url("assets/fonts/SmoothStone-Regular.ttf") format("truetype");
      font-weight:900;
      font-style:normal;
    }
    html{
      // overflow: auto !important;
    }
    body{
      // overflow: auto;
      background-color: $home;
    }
    h1{
      font-family: 'PlayfairDisplay', Arial, Helvetica, sans-serif;
      font-size: 60px;
    }
    h3{
      font-family: 'SmoothStone', Arial, Helvetica, sans-serif;
      font-size: 70px;
    }
    h3{
      color: $green;
    }
    a{
      text-decoration: inherit;
      color: $black;
    }
    .button{
      padding: 25px 50px;
      border: 2px solid #000000;
      width: fit-content;
      font-weight: bold;
      cursor: pointer;
      box-sizing: border-box;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
      transition-timing-function: ease;
    }
    .fade-enter, .fade-leave-to, .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
    *{
      transition: .5s
    }
    // Component name
    $COMPONENT: 'App';
    .App {
        margin: 0;
        font-family: 'GTAmerica', Arial, Helvetica, sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        // -webkit-overflow-scrolling: touch;
        // overflow-x: hidden;
        height: 100vh;
        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN
        .v{
          &-main{
            margin: auto;
            // max-width: 90vw;
            position: relative;
          }
        }

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

