<template>
  <div :class="classBlock">
    <div :class="classBlock+'_background'">
      <div ref="filterColor" :class="classBlock+'_background-filterColor'"></div>
      <div :class="classBlock+'_background-filter'"></div>
      <div :class="classBlock+'_background-img'"></div>
      <div ref="subline" :class="[classBlock+'-subline ']">
        Installateur<br>
        Poseur<br>
        Annecy
      </div>
      <div ref="mouseScroll" :class="classBlock+'_mouse'">
        <mouse-scroll />
      </div>
    </div>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import gsap from 'gsap'
  import MouseScroll from '../molecules/MouseScroll.vue';
  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'HomeBanner',
    // components imported
    components: {MouseScroll},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'HomeBanner',
      delayedCall: null
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      }
    },

    // -------------------------------------------- methods
    methods: {
      showPict() {
        gsap.to(this.$refs.filterColor, {opacity:0.8, duration: 2})
      },
      showBaseline() {
        gsap.to(this.$refs.subline, {opacity:1, duration: 1})
        gsap.to(this.$refs.mouseScroll, {opacity:1, duration: 1})
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {
      this.delayedCall = gsap.delayedCall(0.5, this.showPict);
      this.delayedCall = gsap.delayedCall(1.5, this.showBaseline);
    },

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'HomeBanner';
    .HomeBanner {
        // --------------------------------------------------------------------- CONFIG
        width: 100vw;
        // --------------------------------------------------------------------- BLOCK
        &_background{
          position: relative;
          overflow-x: hidden;
          z-index: 0;
          &-filter{
            position: absolute;
            top: 0;
            left: 0;
            background-color: $green;
            height: 100vh;
            width: 100vw;
            mix-blend-mode: color;
            z-index: 2;
          }
          &-filterColor{
            position: absolute;
            top: 0;
            left: 0;
            background-color: $green;
            height: 100vh;
            width: 100vw;
            opacity: 1;
            z-index: 1;
          }
          &-img{
            position: relative;
            background-image: url('../assets/img/01.jpg');
            background-size: cover;
            background-attachment: fixed;
            background-position: center left;
            display: block;
            background-position: center center;
            height: 100vh;
            width: 100vw;
            z-index: 0;
          }
        }
        &-subline{
          margin: 0;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 100%;
          text-align: center;
          z-index: 3;
          color: $white;
          opacity: 0;
          font-family: 'PlayfairDisplay', Arial, Helvetica, sans-serif;
          font-weight: 600;
          font-size: 50px;
          line-height: 36px;
        }
        &_mouse{
          position: absolute;
          bottom: 20px;
          z-index: 3;
          opacity: 0;
          width: 100%;
              text-align: center;
        }

        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

